<template>
  <div>
    <FiltriRichiestaImportazioni
      @getRichiestaImportazioniList="getRichiestaImportazioniList"
      @resetFilters="resetFilters"
      @resetPaginazione="resetPaginazione"
    />
    <TableRichiestaImportazioni
      @getRichiestaImportazioniList="getRichiestaImportazioniList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  ref,
} from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { trimInput } from "@/composables/trimInput";

import FiltriRichiestaImportazioni from "@/components/richiesta-importazioni/FiltriRichiestaImportazioni.vue";
import TableRichiestaImportazioni from "@/components/richiesta-importazioni/TableRichiestaImportazioni.vue";
import { alertFailed } from "@/composables/swAlert";

export default defineComponent({
  name: "RichiestaImportazioni",
  components: {
    FiltriRichiestaImportazioni,
    TableRichiestaImportazioni,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Richiesta Importazioni", []);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const rowsToSkip = computed(
      () => store.getters.rowsToSkipRichiestaImportazioni
    );
    const fetchRows = computed(
      () => store.getters.fetchRowsRichiestaImportazioni
    );
    const sortColumn = computed(
      () => store.getters.sortColumnRichiestaImportazioni
    );
    const sortOrder = computed(
      () => store.getters.sortOrderRichiestaImportazioni
    );
    const id_tipologia = computed(
      () => store.getters.id_tipologiaRichiestaImportazioni
    );
    const data_richiesta_da = computed(
      () => store.getters.data_richiesta_daRichiestaImportazioni
    );
    const data_richiesta_a = computed(
      () => store.getters.data_richiesta_aRichiestaImportazioni
    );
    const id_stato = computed(
      () => store.getters.id_statoRichiestaImportazioni
    );
    const id_societa = computed(
      () => store.getters.id_societaRichiestaImportazioni
    );
    const chiaveRichiestaImportazioni = computed(
      () => store.getters.chiaveRichiestaImportazioni
    );

    const getRichiestaImportazioniList = () => {
      if (
        chiaveRichiestaImportazioni.value &&
        isNaN(parseInt(chiaveRichiestaImportazioni.value))
      ) {
        alertFailed("La chiave deve essere un numero!");
        return;
      }
      store.dispatch("setStoreListData", {
        keys: {
          id_societa: id_societa.value,
          id_tipologia: id_tipologia.value,
          data_richiesta_da: data_richiesta_da.value,
          data_richiesta_a: data_richiesta_a.value,
          id_stato: id_stato.value,
          rowsToSkip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
          chiave: chiaveRichiestaImportazioni.value,
        },
        apiLink: globalApi.RICHIESTA_IMPORTAZIONI_LIST,
        itemName: "RICHIESTA_IMPORTAZIONI_LIST",
        version: 1,
        method: "post",
      });
    };
    const resetted = ref(false);
    getRichiestaImportazioniList();

    const resetPaginazione = () => {
      store.commit("setCurrentPageRichiestaImportazioni", 1);
    };

    const resetFilters = () => {
      store.commit("resetFiltersRichiestaImportazioni");
      resetted.value = true;
      setTimeout(() => {
        resetted.value = false;
      }, 1000);
      //getRichiestaImportazioniList();
    };

    return {
      getRichiestaImportazioniList,
      resetFilters,
      resetted,
      resetPaginazione,
    };
  },
});
</script>
