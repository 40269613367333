<template>
  <div @click="$emit('imageClick')" style="cursor: pointer">
    <img
      v-show="estensione === 'pdf'"
      src="/media/fit-media/PDF.png"
      style="height: 40px"
    />
    <img
      v-show="estensione === 'doc' || estensione === 'docx'"
      src="/media/fit-media/DOC.png"
      style="height: 40px"
    />

    <img
      src="/media/fit-media/jpg_icon.svg"
      v-show="estensione === 'jpg' || estensione === 'jpeg'"
      style="width: 3rem"
    />
    <img
      src="/media/fit-media/png_icon.png"
      v-show="estensione === 'png'"
      style="width: 3rem"
    />
    <img
      src="/media/fit-media/pptx_icon.svg"
      v-show="estensione === 'ppt' || estensione === 'pptx'"
      style="height: 40px"
    />
    <img
      src="/media/fit-media/icona_xls.svg"
      v-show="estensione === 'xls' || estensione === 'xlsx'"
      style="height: 40px"
    />
    <img
      src="/media/fit-media/icona_zip.svg"
      v-show="estensione === 'zip'"
      style="height: 40px"
    />
  </div>
</template>

<script>
export default {
  name: "PreviewDaEstensione",
  emits: ["imageClick"],
  props: {
    estensione: {
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>
