import * as yup from "yup";

export const schema = yup.object().shape({
  // id_tipologia: yup.number().required("Obbligatorio"),
  // nome_file_originale: yup.string().required("Obbligatorio"),
  // extension: yup.string().required("Obbligatorio"),
  numero_record_dichiarati: yup
    .number()
    .required("Obbligatorio")
    .typeError("Inseriere un numero"),
  nota: yup.string(),
  email: yup.string().email("Inserire una email").required("Obbligatorio"),
});

export const schemaEdit = yup.object().shape({
  id: yup.number().required("Obbligatorio"),
  numero_record_dichiarati: yup.number(),
  nota: yup.string().required("Obbligatorio"),
});
