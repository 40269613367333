<template>
  <div class="modal fade" tabindex="-10" id="modal_add_richiesta_importazioni">
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        class="form"
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
        :v-slot="{ values }"
        :initial-values="formValues"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{
                id_richiesta ? "Visualizza richiesta" : "Inserimento richiesta"
              }}
            </h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="modal_add_richiesta_importazioni_close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body">
            <div
              class="mx-auto my-auto text-center"
              v-if="isLoadingDetail && id_richiesta"
            >
              <div class="spinner-border text-gray-600" role="status"></div>
              <span class="text-gray-600 ps-3 fs-2">Loading</span>
            </div>
            <div
              v-else-if="
                !isLoadingDetail &&
                id_richiesta &&
                !formValues.id_societa &&
                (status == 200 || status == 204)
              "
              class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
            >
              <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
              <span class="text-gray-700 ps-3 fs-2">
                Attenzione! Nessun risultato.
              </span>
              <span
                type="button"
                data-bs-toggle=""
                data-bs-target=""
                aria-expanded="false"
                aria-controls=""
                class="text-gray-600 pt-4"
                @click.prevent="$emit('resetFilters')"
              >
                <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
              ></span>
            </div>
            <div
              v-else-if="
                !isLoadingDetail &&
                id_richiesta &&
                !formValues.id_societa &&
                (status != 200 || status != 204)
              "
              class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
            >
              <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
              <span class="text-gray-700 ps-3 fs-2">
                Attenzione! Si è verificato un errore. Riprovare più tardi.
              </span>
            </div>
            <div class="card-body p-6" v-else>
              <form autocomplete="off">
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-if="alertCampiObb && !id_richiesta"
                >
                  Compila i campi obbligatori
                </div>
                <div class="ps-5 pe-5">
                  <div class="row pb-4">
                    <div class="col-md-7">
                      <label class="fw-bold text-gray-600"
                        >Numero di nominativi*</label
                      >
                      <Field
                        name="numero_record_dichiarati"
                        type="text"
                        class="form-control"
                        :disabled="id_richiesta"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="numero_record_dichiarati"
                      />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-7">
                      <label class="fw-bold text-gray-600"
                        >Indirizzo email di contatto*</label
                      >
                      <Field
                        name="email"
                        type="text"
                        class="form-control"
                        :disabled="id_richiesta"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="email"
                      />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-sm-12">
                      <label class="fw-bold text-gray-600">Note:</label>
                      <Field
                        as="textarea"
                        type="text"
                        class="form-control"
                        placeholder=""
                        aria-label=""
                        name="nota"
                        :disabled="id_richiesta"
                      />
                      <ErrorMessage
                        name="nota"
                        style="color: red"
                        class="ms-1"
                      />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-12 d-flex flex-column">
                      <div class="row ps-3">
                        <Field
                          class="form-check-input mt-1 col-3"
                          type="checkbox"
                          name="veridicita_contenuti_dichiarata"
                          :value="true"
                          :disabled="id_richiesta"
                        />
                        <div class="col-9">
                          <div class="text-align-bottom">
                            Dichiaro che i dati forniti sono veritieri
                          </div>
                        </div>
                        <ErrorMessage
                          name="veridicita_contenuti_dichiarata"
                          style="color: red"
                          class="ms-1"
                        />
                      </div>
                    </div>
                  </div>

                  <DropZone
                    v-if="!id_richiesta"
                    @files-dropped="addFile($event, supportedFormat)"
                    #default="{ dropZoneActive }"
                  >
                    <label for="file-input-add-bac" style="cursor: pointer">
                      <span v-if="dropZoneActive">
                        <span>Lascia qui il file </span>
                        <span class="smaller">per aggiungerlo</span>
                      </span>
                      <span v-else>
                        <span>Trascina qui il tuo file</span>
                        <span class="smaller">
                          oppure
                          <strong><em>clicca qui</em></strong>
                          per selezionare il file
                        </span>
                      </span>
                    </label>
                    <input
                      type="file"
                      id="file-input-add-bac"
                      style="display: none"
                      :accept="supportedFormat"
                      @change="onInputChange"
                    />
                    <ul class="ps-0" v-if="file.id">
                      <FilePreview
                        :key="file.id"
                        :file="file"
                        tag="li"
                        @remove="removeFile"
                      />
                    </ul>
                  </DropZone>

                  <ul class="ps-0" v-else>
                    <li
                      class="d-flex align-center cursor-pointer"
                      @click="downloadDocumento(formValues)"
                    >
                      <PreviewDaEstensione
                        :estensione="
                          formValues.file_name.substring(
                            formValues.file_name.lastIndexOf('.') + 1,
                            formValues.file_name.length
                          )
                        "
                      />
                      <p class="my-auto ms-3">
                        {{ formValues.file_name }}
                      </p>
                    </li>
                  </ul>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="modal_add_richiesta_importazioni_close_button"
            >
              Chiudi
            </button>
            <button
              v-if="!id_richiesta"
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              {{ id_richiesta ? "Modifica" : "Aggiungi" }}
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance, computed, ref, watch } from "vue";
import { Form, ErrorMessage, Field } from "vee-validate";
import { useStore } from "vuex";
import { format } from "@/composables/formatDate";

import "vue3-date-time-picker/dist/main.css";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { schema, schemaEdit } from "@/schemas/schemaRichiestaImportazioni";
import {
  callFunctionAfterAlert,
  alertFailed,
  alertSuccess,
} from "@/composables/swAlert";

import DropZone from "@/components/components-fit/Drag-and-Drop-File/DropZone.vue";
import FilePreview from "@/components/components-fit/Drag-and-Drop-File/FilePreview.vue";
import useFileList from "@/composables/file-list";
import { sendFiles } from "@/requests/fileSender";
import { viewRichiesta } from "@/requests/RichiesteImportazioni";
import PreviewDaEstensione from "@/components/components-fit/utility/PreviewDaEstensione.vue";
import { downloadElementRichiestaImportazioni } from "@/requests/downloadFiles";

export default defineComponent({
  name: "addEvento",
  props: {
    id_richiesta: {
      type: Number,
    },
  },
  components: {
    Loading,
    Form,
    ErrorMessage,
    Field,
    DropZone,
    FilePreview,
    PreviewDaEstensione,
  },
  emits: ["resetId", "getList"],
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const alertCampiObb = ref(false);

    const spliceComuniIstat = () => {
      store.commit("spliceArrayFromName", "comuni_istat");
    };

    const setComuni = (comuneString, id) => {
      if (comuneString.length >= 3) {
        store.dispatch("setStoreData", {
          keys: {
            attivo: true,
            nomec: comuneString,
            codp: id,
          },
          apiLink: globalApi.COMUNI_ISTAT_LIST,
        });
      }
    };

    const disableButton = ref(false);
    const isLoading = ref(false);
    let bodyFormData = new FormData();

    const onSubmit = async (values, { resetForm }) => {
      if (props.id_richiesta) {
        // todo: inserire chiamata ad edit richiesta
        // todo: modificare il template in alto per lasciare il titolo sempre visibile
        await callFunctionAfterAlert(
          globalApi.RICHIESTA_IMPORTAZIONI_EDIT,
          {
            id: props.id_richiesta,
            numero_record_dichiarati: values.numero_record_dichiarati,
            veridicita_contenuti_dichiarata:
              values.veridicita_contenuti_dichiarata
                ? values.veridicita_contenuti_dichiarata
                : false,
            nota: values.nota,
          },
          `Sei sicuro di voler modificare la richiesta?`,
          "Richiesta modificata con successo",
          "Attenzione! Si è verificato un problema nella modifica della richiesta. Riprovare più tardi"
        ).then(({ success }) => {
          if (success) {
            document
              .getElementById("modal_add_richiesta_importazioni_close")
              .click();

            emit("getList");

            setTimeout(() => {
              emit("resetId");
            }, 1000);
          }
        });
      } else {
        disableButton.value = true;
        isLoading.value = true;
        if (file.value.file) {
          bodyFormData.append("file", file.value.file);
          file.value.status = "loading";
        } else {
          alertFailed("Inserire un file!");
          bodyFormData = new FormData();
          disableButton.value = false;
          isLoading.value = false;
          return;
        }
        Object.entries(values).map((item) => {
          //
          if (item[0] != "veridicita_contenuti_dichiarata")
            bodyFormData.append(item[0], item[1]);
        });
        // ID TIPOLOGIA FISSATA AD UNO PERCHE UNICA OPZIONE DISPONIBILE ()
        bodyFormData.append(
          "veridicita_contenuti_dichiarata",
          values.veridicita_contenuti_dichiarata
            ? values.veridicita_contenuti
            : false
        );
        bodyFormData.append("id_tipologia", 1);
        bodyFormData.append("nome_file_originale", file.value.file.name);

        await sendFiles(
          bodyFormData,
          globalApi.AGGIUNGI_DOCUMENTO_RICHIESTA_IMPORTAZIONE
        ).then((res) => {
          const { status, data } = res;
          if (status != 200) {
            file.value.status = false;
            alertFailed(
              data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
          } else {
            file.value.status = true;
            alertSuccess(
              "Documento aggiunto con successo",
              "modal_add_richiesta_importazioni"
            );
            emit("getList");

            setTimeout(() => {
              emit("resetId");
            }, 1000);
            //reset form
            bodyFormData = new FormData();
            file.value = {};
            resetForm();
          }
          disableButton.value = false;
          isLoading.value = false;
        });
      }
    };

    const onInvalidSubmit = (v) => {
      alertCampiObb.value = true;
    };

    const { file, addFile, removeFile } = useFileList();

    const supportedFormat = ref(".xlsx, .xls");
    function onInputChange(e) {
      addFile(e.target.files, supportedFormat.value);
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    }

    const formValues = ref({});
    const isLoadingDetail = ref(false);
    const status = ref(0);
    const getRichiesta = async (value) => {
      formValues.value = {};
      isLoadingDetail.value = true;
      await viewRichiesta(value).then((res) => {
        isLoadingDetail.value = false;
        status.value = res.status;
        if (res.data && res.data.statusCode === 400) {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        } else {
          formValues.value = { ...res.data.results[0] };
        }
      });
    };

    watch(
      () => props.id_richiesta,
      async (value) => {
        if (value != null) {
          await getRichiesta(value);
          file.value = {};
        }
      }
    );

    const downloadDocumento = async (data) => {
      // if (!downloadingFile.value) {
      // downloadingFile.value = true;

      await downloadElementRichiestaImportazioni(data.id);
      // downloadingFile.value = false;
      // }
    };
    return {
      downloadDocumento,
      isLoadingDetail,
      status,
      onInputChange,
      supportedFormat,
      file,
      addFile,
      removeFile,
      schemaEdit,
      schema,
      alertCampiObb,
      onSubmit,
      onInvalidSubmit,
      format,
      stagioni: computed(() => store.getters.stagioni),
      istat_regioni_province: computed(() =>
        store.getters.getStateFromName("istat_regioni_province")
      ),
      province: computed(() => store.getters.getStateFromName("province")),
      spliceComuniIstat,
      setComuni,
      comuniIstat: computed(() =>
        store.getters.getStateFromName("comuni_istat")
      ),
      comitati: computed(() => store.getters.getStateFromName("comitati")),
      societa: computed(() => store.getters.societaNomeCodAff),
      disableButton,
      isLoading,
      formValues,
    };
  },
});
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

.modal-dialog {
  margin-top: 3rem;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
