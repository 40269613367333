import services from "@/axios/dbManag";

export const viewRichiesta = (id) => {
  return services.dbManag
    .post("/importazioni/richieste/societa/view", {
      id,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => e.response);
};

export const deleteRichiesta = (id) => {
  return services.dbManag
    .post("/importazioni/richieste/societa/delete", {
      id,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => e.response);
};
