<template>
  <div>
    <div class="card shadow">
      <form autocomplete="off">
        <div
          class="pt-4 pb-4 pe-4 ps-4"
          @keyup.enter="$emit('getRichiestaImportazioniList')"
        >
          <div class="row pb-4">
            <!-- FILTRO TIPOLOGIA PER IMPLEMENTAZIONIU FUTURE -->
            <div class="col-sm-6 filter" v-if="1 == 2">
              <SelectInputEvento
                :options="richieste_societa_tipologie_lookup"
                name="id_tipologia"
                placeholder="Tipologia"
                :value="id_tipologiaRichiestaImportazioni"
                @changeSelect="
                  setFiltroRichiestaImportazioni(
                    'id_tipologiaRichiestaImportazioni',
                    $event
                  )
                "
              />
            </div>
            <!-- FINE FILTRO TIPOLOGIA PER IMPLEMENTAZIONIU FUTURE -->
            <div class="col-sm-4 filter">
              <div>
                <Datepicker
                  v-model="data_richiesta_daRichiestaImportazioni"
                  placeholder="Data richiesta da"
                  :format="format"
                  :monthChangeOnScroll="false"
                  :enableTimePicker="false"
                  inputClassName="h-28"
                  @update:modelValue="
                    setFiltroRichiestaImportazioni(
                      'data_richiesta_daRichiestaImportazioni',
                      $event
                    )
                  "
                  autoApply
                />
              </div>
            </div>
            <div class="col-sm-4 filter">
              <div>
                <Datepicker
                  v-model="data_richiesta_aRichiestaImportazioni"
                  placeholder="Data richiesta a"
                  :format="format"
                  :monthChangeOnScroll="false"
                  :enableTimePicker="false"
                  inputClassName="h-28"
                  @update:modelValue="
                    setFiltroRichiestaImportazioni(
                      'data_richiesta_aRichiestaImportazioni',
                      $event
                    )
                  "
                  autoApply
                />
              </div>
            </div>
            <div class="col-md-4 filter">
              <SelectInput
                :options="richieste_societa_stati_lookup"
                name="id_stato"
                placeholder="Stato"
                :value="id_statoRichiestaImportazioni"
                @changeSelect="
                  setFiltroRichiestaImportazioni(
                    'id_statoRichiestaImportazioni',
                    $event
                  );
                  $emit('resetPaginazione');
                "
              />
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-md-6 filter">
              <div class="filter">
                <div class="dp__input_wrap">
                  <input
                    name="nome_societa"
                    type="text"
                    placeholder="Società"
                    class="form-control"
                    autocomplete="off"
                    @input="getSocieta($event.target.value)"
                    :value="nome_societaRichiestaImportazioni"
                  />
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    class="dp__icon dp__clear_icon dp__input_icons"
                    @click="
                      resetSocieta();
                      setFiltroRichiestaImportazioni(
                        'id_societaRichiestaImportazioni',
                        null
                      );
                      setFiltroRichiestaImportazioni(
                        'nome_societaRichiestaImportazioni',
                        ''
                      );
                      $emit('resetPaginazione');
                    "
                  >
                    <path
                      d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                    ></path>
                    <path
                      d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                    ></path>
                  </svg>
                </div>
                <ListSocieta
                  :focusOnInput="true"
                  :list="societa"
                  @hideList="
                    inputFocus = null;
                    resetSocieta;
                  "
                  @selectedSocieta="
                    setFiltroRichiestaImportazioni(
                      'id_societaRichiestaImportazioni',
                      $event.value
                    );
                    setFiltroRichiestaImportazioni(
                      'nome_societaRichiestaImportazioni',
                      $event.name
                    );
                    resetSocieta();
                    $emit('resetPaginazione');
                    inputFocus = null;
                  "
                ></ListSocieta>
              </div>
            </div>
            <div class="col-md-6 filter">
              <input
                type="text"
                class="form-control"
                placeholder="Chiave"
                aria-label=""
                :value="chiaveRichiestaImportazioni"
                @input="
                  setFiltroRichiestaImportazioni(
                    'chiaveRichiestaImportazioni',
                    $event.target.value
                  )
                "
              />
            </div>
          </div>
          <div class="text-center mt-5">
            <button
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('getRichiestaImportazioniList')"
            >
              Cerca
              <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="
                $emit('resetFilters');
                $emit('getRichiestaImportazioniList');
                rerender++;
              "
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import SelectInputEvento from "@/components/components-fit/utility/inputs/SelectInputEvento.vue";
import { format } from "@/composables/formatDate";
import "vue3-date-time-picker/dist/main.css";
import ListSocieta from "@/components/components-fit/utility/ListSocieta.vue";
import Datepicker from "vue3-date-time-picker";

export default defineComponent({
  name: "filtriAttestatiQualifiche",
  emits: ["resetFilters", "getRichiestaImportazioniList", "resetPaginazione"],
  props: {
    resetted: {
      type: Boolean,
    },
  },
  components: {
    SelectInput,
    ListSocieta,
    SelectInputEvento,
    Datepicker,
  },
  setup(props) {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const richieste_societa_tipologie_lookup = computed(() =>
      store.getters.getStateFromName("richieste_societa_tipologie")
    );
    const richieste_societa_stati_lookup = computed(() =>
      store.getters.getStateFromName("richieste_societa_stati")
    );
    const comitati = computed(() => store.getters.getStateFromName("comitati"));
    const keysRichiestaImportazioni = ref("irst|irss");
    if (
      !richieste_societa_tipologie_lookup.value ||
      !richieste_societa_stati_lookup.value
    ) {
      store.dispatch("setStoreData", {
        keys: { keys: keysRichiestaImportazioni.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const setFiltroRichiestaImportazioni = (
      nome_variabile,
      valore_variabile
    ) => {
      store.commit("setFilterRichiestaImportazioni", {
        nome_variabile,
        valore_variabile,
      });
    };

    const getSocieta = (string) => {
      if (string === "") {
        store.commit("emptySocietaNomeCodAff");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getSocietaNomeCodAff", string);
    };
    const resetSocieta = () => {
      store.commit("emptySocietaNomeCodAff");
    };

    const rerender = ref(0);

    return {
      rerender,
      resetSocieta,
      getSocieta,
      setFiltroRichiestaImportazioni,

      societa: computed(() => store.getters.societaNomeCodAff),
      format,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedVERIFICA_ANOMALIE_LIST")
      ),

      richieste_societa_tipologie_lookup,
      richieste_societa_stati_lookup,
      comitati,
      id_tipologiaRichiestaImportazioni: computed(
        () => store.getters.id_tipologiaRichiestaImportazioni
      ),
      id_statoRichiestaImportazioni: computed(
        () => store.getters.id_statoRichiestaImportazioni
      ),
      id_societaRichiestaImportazioni: computed(
        () => store.getters.id_societaRichiestaImportazioni
      ),
      nome_societaRichiestaImportazioni: computed(
        () => store.getters.nome_societaRichiestaImportazioni
      ),
      data_richiesta_daRichiestaImportazioni: computed(
        () => store.getters.data_richiesta_daRichiestaImportazioni
      ),
      data_richiesta_aRichiestaImportazioni: computed(
        () => store.getters.data_richiesta_aRichiestaImportazioni
      ),
      chiaveRichiestaImportazioni: computed(
        () => store.getters.chiaveRichiestaImportazioni
      ),
    };
  },
  computed: {},
});
</script>

<style></style>
