import { isConstructorDeclaration } from "typescript";
import { ref } from "vue";
import { alertFailed, alertSuccess } from "@/composables/swAlert";

// export default function () {
//   const files = ref([]);

//   function addFiles(newFiles) {
//     let newUploadableFiles = [...newFiles]
//       .map((file) => new UploadableFile(file))
//       .filter((file) => !fileExists(file.id));
//     files.value = files.value.concat(newUploadableFiles);
//   }

//   function fileExists(otherId) {
//     return files.value.some(({ id }) => id === otherId);
//   }

//   function removeFile(file) {
//     const index = files.value.indexOf(file);

//     if (index > -1) files.value.splice(index, 1);
//   }

//   return { files, addFiles, removeFile };
// }

export default function () {
  const file = ref({});

  function addFile(newFile, supportedFormat) {
    const arraySupportedFormat = supportedFormat.split(",");
    const canUploadFile = arraySupportedFormat.some((element) => {
      const noSpacesFormat = element.replace(/ /g, "");
      return newFile[0].name.indexOf(noSpacesFormat) != -1;
    });

    if (!canUploadFile) {
      alertFailed(
        `Puoi includere solo file con i seguenti formati ${supportedFormat}`
      );
      return;
    }
    let newUploadableFile = [...newFile].map(
      (file) => new UploadableFile(file)
    );
    if (!file.value.id) {
      file.value = { ...newUploadableFile[0] };
    } else {
      fileExists(newFile.id) ? "" : (file.value = { ...newUploadableFile[0] });
    }
  }

  function fileExists(otherId) {
    return file.value.id === otherId;
  }

  function removeFile() {
    file.value = {};
  }

  return { file, addFile, removeFile };
}

class UploadableFile {
  constructor(file) {
    this.file = file;
    this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`;
    this.url = URL.createObjectURL(file);
    this.status = null;
  }
}
