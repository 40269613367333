<template>
  <div>
    <loading
      :active="isLoading || downloadingFile"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row mt-5 justify-content-between">
      <ModalAddRichiestaImportazioni
        :id_richiesta="id_richiesta_selected"
        @resetId="id_richiesta_selected = null"
        @getList="$emit('getRichiestaImportazioniList')"
      />
      <div
        class="col-md-3"
        v-if="!isEnabled('fnImportazioniRichiesteSocietaManage')"
      >
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-6 text-center"
          data-bs-toggle="modal"
          data-bs-target="#modal_add_richiesta_importazioni"
          @click="id_richiesta_selected = null"
        >
          <i class="bi bi-plus fs-6 text-gray-700 me-1"></i>aggiungi richiesta
        </button>
      </div>
      <div class="col-md-3 text-center" v-else>
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-6 text-center"
          data-bs-toggle="modal"
          data-bs-target="#modal_esporta_lista_filtrata_anomalie"
          @click="esportaRichiesteImportazione"
        >
          <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta lista
          filtrata
        </button>
        <ModalEsportaRichiestaImportazioni />
      </div>
      <div class="col-md-6 text-center">
        <a
          type="button"
          href="/media/fit-media/Documenti/esempio_importazione_rdc.xlsx"
          target="_blank"
          class="btn btn-light text-gray-700 fs-6 text-center"
        >
          <i class="bi bi-plus fs-6 text-gray-700 me-1"></i>scarica file di
          esempio
        </a>
      </div>
      <div class="col-md-3">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          elementi di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />

    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        RICHIESTA_IMPORTAZIONI_LIST.length === 0 &&
        loaded &&
        (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>

    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="RICHIESTA_IMPORTAZIONI_LIST"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="
          setFiltroRichiestaImportazioni(
            'fetchRowsRichiestaImportazioni',
            $event
          );
          $emit('getRichiestaImportazioniList');
        "
        @current-change="
          setCurrentPageRichiestaImportazioni($event);
          $emit('getRichiestaImportazioniList');
        "
      >
        <template v-slot:cell-progressivo="{ row: data }">
          <div>
            {{ data.progressivo }}
          </div>
        </template>
        <template v-slot:cell-data_ora_richiesta="{ row: data }">
          <div>
            {{ data.data_ora_richiesta }}
          </div>
        </template>
        <template v-slot:cell-societa="{ row: data }">
          <div class="tab-long">
            {{ data.denominazione }} ({{ data.cod_affiliazione }})
          </div>
        </template>
        <template v-slot:cell-email_contatto="{ row: data }">
          <div class="tab-long">
            {{ data.email }}
          </div>
        </template>
        <template v-slot:cell-numero_record_dichiarati="{ row: data }">
          <div>
            {{ data.numero_record_dichiarati }}
          </div>
        </template>
        <template v-slot:cell-stato="{ row: data }">
          <div>
            <h1
              class="badge"
              :class="{
                'badge-light': data.id_stato == 1,
                'badge-light-warning': data.id_stato == 2,
                'badge-light-primary': data.id_stato == 3,
                'badge-light-success': data.id_stato == 4,
              }"
            >
              {{ data.stato }}
            </h1>
          </div>
        </template>
        <template v-slot:cell-options="{ row: data }">
          <div class="btn-group">
            <button
              class="btn btn-sm dropdown"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
            </button>
            <ul class="dropdown-menu">
              <span
                class="cursor-pointer"
                @click="id_richiesta_selected = data.id"
                data-bs-toggle="modal"
                data-bs-target="#modal_add_richiesta_importazioni"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-eye text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7" type="button">
                        Visualizza
                      </span>
                    </div>
                  </div>
                </li>
              </span>
              <span @click="cancellaRichiesta(data)">
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-trash text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7" type="button">
                        Cancella
                      </span>
                    </div>
                  </div>
                </li></span
              >
              <a href="#" @click="downloadDocumento(data)">
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-download text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7" type="button">
                        Download
                      </span>
                    </div>
                  </div>
                </li></a
              >
              <template
                v-for="tipo_richiesta in richieste_societa_stati_lookup"
                :key="tipo_richiesta.id"
              >
                <a
                  v-if="
                    tipo_richiesta.id > 2 &&
                    tipo_richiesta.id > data.id_stato &&
                    isEnabled('fnImportazioniRichiesteSocietaManage')
                  "
                  href="#"
                  @click="modalRichiesta(tipo_richiesta, data)"
                >
                  <li role="presentation" class="navi-item">
                    <div class="b-dropdown-text text-start py-2 px-4">
                      <div class="navi-link">
                        <i
                          class="bi text-dark fs-5"
                          :class="{
                            'bi-hourglass': tipo_richiesta.id == 3,
                            'bi-check': tipo_richiesta.id == 4,
                          }"
                        ></i>
                        <span class="text-dark ps-1 fs-7" type="button">
                          {{ tipo_richiesta.label }}
                        </span>
                      </div>
                    </div>
                  </li></a
                >
              </template>
            </ul>
          </div>
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { ref, computed, getCurrentInstance } from "vue";
import Loading from "vue3-loading-overlay";
import isEnabled from "@/composables/isEnabled.js";

import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import {
  alertFailed,
  alertSuccess,
  callFunctionAfterAlert,
} from "@/composables/swAlert";

import ModalAddRichiestaImportazioni from "@/components/richiesta-importazioni/ModalAddRichiestaImportazioni.vue";
import { downloadElementRichiestaImportazioni } from "@/requests/downloadFiles";
import ModalEsportaRichiestaImportazioni from "./ModalEsportaRichiestaImportazioni.vue";
export default {
  name: "TableAlbo",
  components: {
    Datatable,
    Loading,
    ModalAddRichiestaImportazioni,
    ModalEsportaRichiestaImportazioni,
  },
  emits: ["getRichiestaImportazioniList", "resetFilters"],
  setup(props, { emit }) {
    const store = useStore();

    const refreshRichiestaImportazioniList = () => {
      emit("getRichiestaImportazioniList");
    };

    const tableHeader = ref([
      {
        name: "Chiave",
        key: "progressivo",
        sortable: true,
      },
      {
        name: "Data Ora Richiesta",
        key: "data_ora_richiesta",
        sortable: true,
      },
      {
        name: "Società",
        key: "societa",
        sortable: false,
      },
      {
        name: "Email di contatto",
        key: "email_contatto",
        sortable: false,
      },
      {
        name: "Numero nominativi",
        key: "numero_record_dichiarati",
        sortable: false,
      },
      {
        name: "Stato",
        key: "stato",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const RICHIESTA_IMPORTAZIONI_LIST = computed(() =>
      store.getters.getStateFromName("resultsRICHIESTA_IMPORTAZIONI_LIST")
    );

    const setCurrentPageRichiestaImportazioni = (e) => {
      store.commit("setCurrentPageRichiestaImportazioni", e);
    };

    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnRichiestaImportazioni", columnName);
      store.commit("setSortOrderRichiestaImportazioni", order);
      emit("getRichiestaImportazioniList");
    };

    const isLoading = ref(false);

    const modalRichiesta = async (tipo_richiesta, richiestaDaModificare) => {
      await callFunctionAfterAlert(
        `/importazioni/richieste/societa/stato/${
          tipo_richiesta.id == 2
            ? "presa-in-carico"
            : tipo_richiesta.id == 3
            ? "in-lavorazione"
            : tipo_richiesta.id == 4
            ? "elaborato"
            : ""
        }`,
        {
          id: richiestaDaModificare.id,
        },
        `Sei sicuro di voler spostare la richiesta nello stato "${tipo_richiesta.label}"?`,
        "Stato della richiesta modificato con successo",
        "Attenzione! Si è verificato un errore. Riprovare più tardi"
      ).then(({ success }) => {
        if (success) {
          emit("getRichiestaImportazioniList");
        }
      });
    };

    const id_richiesta_selected = ref(null);

    const cancellaRichiesta = async (richiesta) => {
      await callFunctionAfterAlert(
        globalApi.RICHIESTA_IMPORTAZIONI_DEL,
        {
          id: richiesta.id,
        },
        `Sei sicuro di voler eliminare la richiesta?`,
        "Richiesta eliminata con successo",
        "Attenzione! Si è verificato un problema nell'eliminazione della richiesta. Riprovare più tardi"
      ).then(({ success }) => {
        if (success) {
          emit("getRichiestaImportazioniList");
        }
      });
    };

    const downloadingFile = ref(false);
    const downloadDocumento = async (data) => {
      if (!downloadingFile.value) {
        downloadingFile.value = true;
        await downloadElementRichiestaImportazioni(data.id);
        downloadingFile.value = false;
        setTimeout(() => {
          emit("getRichiestaImportazioniList");
        }, 1500);
      }
    };

    const setFiltroRichiestaImportazioni = (
      nome_variabile,
      valore_variabile
    ) => {
      store.commit("setFilterRichiestaImportazioni", {
        nome_variabile,
        valore_variabile,
      });
    };

    return {
      downloadDocumento,
      downloadingFile,
      cancellaRichiesta,
      id_richiesta_selected,
      modalRichiesta,
      isLoading,
      setCurrentPageRichiestaImportazioni,
      setFiltroRichiestaImportazioni,
      RICHIESTA_IMPORTAZIONI_LIST,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedRICHIESTA_IMPORTAZIONI_LIST")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordRICHIESTA_IMPORTAZIONI_LIST")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusRICHIESTA_IMPORTAZIONI_LIST")
      ),
      currentPage: computed(
        () => store.getters.currentPageRichiestaImportazioni
      ),
      rowsToSkip: computed(() => store.getters.rowsToSkipRichiestaImportazioni),
      fetchRows: computed(() => store.getters.fetchRowsRichiestaImportazioni),
      sortColumn: computed(() => store.getters.sortColumnRichiestaImportazioni),
      sortOrder: computed(() => store.getters.sortOrderRichiestaImportazioni),
      setSortOrderColumn,
      tableHeader,
      isEnabled,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      ricercaDopoSelezioneSelectAnomalie: computed(
        () => store.getters.ricercaDopoSelezioneSelectAnomalie
      ),
      richieste_societa_stati_lookup: computed(() =>
        store.getters.getStateFromName("richieste_societa_stati")
      ),
    };
  },
};
</script>

<style scoped>
:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}
</style>
